import appConfigFile from './appConfig.json';
import localEnvVariables from './localEnvVariables.json';

const runtimeEnvironmentVariables = {
  // allows injection of values through replacement with sed at runtime
  // through docker-entrypoint.sh
  // DO NOT SPLIT the values here, as they're being replaced at runtime
  // with a blunt sed string replace in docker-entrypoint.sh!
  ENVIRONMENT: 'placeholder-ENVIRONMENT-',
  TAG_MANAGER_ID: 'placeholder-TAG_MANAGER_ID-',
  REALESTATE_URL: 'placeholder-REALESTATE_URL-',
  JOBS_URL: 'placeholder-JOBS_URL-',
  COGNITO_POOL_ID: 'placeholder-COGNITO_POOL_ID-',
  COGNITO_POOL_WEB_CLIENT_ID: 'placeholder-COGNITO_POOL_WEB_CLIENT_ID-',
  AWS_REGION: 'placeholder-AWS_REGION-',
  JOBS_DATA_WAREHOUSE_API_URL: 'placeholder-JOBS_DATA_WAREHOUSE_API_URL-',
  REAL_ESTATE_DATA_WAREHOUSE_API_URL: 'placeholder-REAL_ESTATE_DATA_WAREHOUSE_API_URL-',
  API_REQUEST_KEY: 'placeholder-API_REQUEST_KEY-',
  AUTH_API_URL: 'placeholder-AUTH_API_URL-',
  AUTH_API_KEY: 'placeholder-AUTH_API_KEY-',
  SOURCEPOINT_COOKIE_CONSENT_BASE_ENDPOINT: 'placeholder-SOURCEPOINT_COOKIE_CONSENT_BASE_ENDPOINT-',
  SOURCEPOINT_COOKIE_CONSENT_ACCOUNT_ID: 'placeholder-SOURCEPOINT_COOKIE_CONSENT_ACCOUNT_ID-',
  SOURCEPOINT_COOKIE_CONSENT_PROPERTY_ID: 'placeholder-SOURCEPOINT_COOKIE_CONSENT_PROPERTY_ID-',
  SOURCEPOINT_COOKIE_CONSENT_PRIVACY_MANAGER_ID:
    'placeholder-SOURCEPOINT_COOKIE_CONSENT_PRIVACY_MANAGER_ID-',
};

const getRuntimeEnvironmentVariable = (name) => {
  if (process.env.NODE_ENV === 'development') {
    return localEnvVariables[name] || null;
  }

  if (!runtimeEnvironmentVariables[name]) return null;

  if (runtimeEnvironmentVariables[name].indexOf('placeholder-') === 0) return null;

  return runtimeEnvironmentVariables[name];
};

export const ENVIRONMENT = getRuntimeEnvironmentVariable('ENVIRONMENT') || 'development';
export const TAG_MANAGER_ID =
  getRuntimeEnvironmentVariable('TAG_MANAGER_ID') || appConfigFile.TAG_MANAGER_ID;
export const publicUrl = process.env.PUBLIC_URL || '';
export const realEstateUrl = getRuntimeEnvironmentVariable('REALESTATE_URL') || '/immobilien';
export const jobsUrl = getRuntimeEnvironmentVariable('JOBS_URL') || '/jobs';
export const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
export const dataProtectionUrl = 'https://www.derstandard.at/datenschutz';
export const FINDEN_CONTACT_EMAIL = 'info@finden.at';
export const JOBS_DATA_WAREHOUSE_API_URL = getRuntimeEnvironmentVariable(
  'JOBS_DATA_WAREHOUSE_API_URL',
);
export const REAL_ESTATE_DATA_WAREHOUSE_API_URL = getRuntimeEnvironmentVariable(
  'REAL_ESTATE_DATA_WAREHOUSE_API_URL',
);
export const REACT_APP_USER_AREA_ENABLED_DEV = appConfigFile.REACT_APP_USER_AREA_ENABLED_DEV;
export const REACT_APP_USER_AREA_ENABLED_PROD = appConfigFile.REACT_APP_USER_AREA_ENABLED_PROD;
export const REACT_APP_AUTH_ENABLED_DEV = appConfigFile.REACT_APP_AUTH_ENABLED_DEV;
export const REACT_APP_AUTH_ENABLED_PROD = appConfigFile.REACT_APP_AUTH_ENABLED_PROD;

export const COGNITO_POOL_ID = getRuntimeEnvironmentVariable('COGNITO_POOL_ID');
export const COGNITO_POOL_WEB_CLIENT_ID = getRuntimeEnvironmentVariable(
  'COGNITO_POOL_WEB_CLIENT_ID',
);
export const AWS_REGION = getRuntimeEnvironmentVariable('AWS_REGION');
export const API_REQUEST_KEY = getRuntimeEnvironmentVariable('API_REQUEST_KEY');

export const AUTH_API_URL = getRuntimeEnvironmentVariable('AUTH_API_URL');
export const AUTH_API_KEY = getRuntimeEnvironmentVariable('AUTH_API_KEY');

export const HARDCODED_REALESTATE_URL_PATH = '/immobilien';

export const SOURCEPOINT_COOKIE_CONSENT_BASE_ENDPOINT = getRuntimeEnvironmentVariable(
  'SOURCEPOINT_COOKIE_CONSENT_BASE_ENDPOINT',
);
export const SOURCEPOINT_COOKIE_CONSENT_ACCOUNT_ID = getRuntimeEnvironmentVariable(
  'SOURCEPOINT_COOKIE_CONSENT_ACCOUNT_ID',
);
export const SOURCEPOINT_COOKIE_CONSENT_PROPERTY_ID = getRuntimeEnvironmentVariable(
  'SOURCEPOINT_COOKIE_CONSENT_PROPERTY_ID',
);

export const SOURCEPOINT_COOKIE_CONSENT_PRIVACY_MANAGER_ID = getRuntimeEnvironmentVariable(
  'SOURCEPOINT_COOKIE_CONSENT_PRIVACY_MANAGER_ID',
);

export const IMGPROXY_KEY =
  getRuntimeEnvironmentVariable('IMGPROXY_KEY') || '7e0def6de0668ef747fdb24c05fc6fe0';
export const IMGPROXY_SALT =
  getRuntimeEnvironmentVariable('IMGPROXY_SALT') || '6be5381f5516f64d41ff1029b19ddf5f';
export const IMGPROXY_SIGNATURE_SIZE =
  Number(getRuntimeEnvironmentVariable('IMGPROXY_SIGNATURE_SIZE')) || 4;
export const IMGPROXY_SERVER_URL =
  getRuntimeEnvironmentVariable('IMGPROXY_SERVER_URL') || 'https://ic.ds.at';

export const imgProxySignatureConfig = {
  key: IMGPROXY_KEY,
  salt: IMGPROXY_SALT,
  signatureSize: IMGPROXY_SIGNATURE_SIZE,
  baseUrl: IMGPROXY_SERVER_URL,
};
