import React from 'react';

import { Routes } from './Routes';
import { Layout } from './modules/Layout';
import { UserContextProvider, AppContextProvider } from './libs/context';

export const App = () => {
  return (
    <AppContextProvider>
      <UserContextProvider>
        <Layout className="body">
          <Routes />
        </Layout>
      </UserContextProvider>
    </AppContextProvider>
  );
};
