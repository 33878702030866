import React from 'react';

import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  FloatingActionButton,
  ICON_VARIANT,
  Icon,
} from 'shared-frontend-components/dist/atoms';

import { useJobsMutation } from '../../../config/api';
import { LG_SCREEN } from '../../../config/globalConstants';
import { buildUrlForSearchAgentItem, pushDataLayer } from '../../../libs/helpers/helpers';
import { extendUrlToFullVersion } from '../../../libs/helpers/stringHelpers';
import { DELETE_SEARCH_AGENT_MUTATION, SEARCHES } from '../searchAgentQueries';
import { SearchAgentBody } from './SearchAgentBody';

import './_searchAgentItem.scss';

export const SearchAgentItem = ({ searchAgentItem }) => {
  const { id, vertical, searchUrl } = searchAgentItem || {};

  const [deleteJobSearch] = useJobsMutation(DELETE_SEARCH_AGENT_MUTATION, {
    variables: {
      id: parseInt(id),
    },
    update: (cache, { data }) => updateFunctionAfterDelete(cache, data, SEARCHES, 'searches'),
  });

  const updateFunctionAfterDelete = (cache, data, query, queryName) => {
    const { deleteSearch } = data || {};

    if (!deleteSearch) return;

    const cachedQueryData = cache.readQuery({ query });

    const newData = cachedQueryData[queryName].filter(({ id }) => parseInt(id) !== deleteSearch);

    cache.writeQuery({
      query,
      data: { [queryName]: newData },
    });
  };

  const redirectProps =
    window.innerWidth > LG_SCREEN ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  const detailPageUrl = buildUrlForSearchAgentItem(searchUrl);

  const extenderDetailPageUrl = extendUrlToFullVersion(detailPageUrl);

  const onDeleteHandle = (event) => {
    event.stopPropagation();
    event.preventDefault();

    pushDataLayer({
      category: 'Userprofile',
      event: 'deleteObjectAlert',
      objectId: id,
      objectVertical: vertical,
    });

    return deleteJobSearch();
  };

  return (
    <div className="search-agent-item_container">
      <a className="search-agent-item_link" href={extenderDetailPageUrl} {...redirectProps}>
        <SearchAgentBody searchAgentItem={searchAgentItem} />
      </a>
      <FloatingActionButton
        variant={BUTTON_VARIANT.SECONDARY}
        size={BUTTON_SIZE.SMALL}
        onClick={onDeleteHandle}
        margin="20px"
      >
        <Icon variant={ICON_VARIANT.BIN} size={{ width: '24px', height: '24px' }} />
      </FloatingActionButton>
    </div>
  );
};
