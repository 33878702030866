import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../libs/context';

import { NotFoundContent } from 'shared-frontend-components/dist/molecules';
import { useHistory } from 'react-router-dom';
import { publicUrl } from '../../config/appConfig';

export const NotFound = () => {
  const { t } = useTranslation('common');
  const { extendedPageLoadEvent: pageLoadEvent } = useContext(UserContext);
  const history = useHistory();

  useEffect(
    () =>
      pageLoadEvent({
        pageType: 'error',
        errorType: '404',
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleButtonClick = () => {
    history.push(publicUrl);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <NotFoundContent
        mainText={t('notFound.title')}
        goBackButton={{
          label: t('notFound.buttonLabel'),
          onClick: handleButtonClick,
        }}
      />
    </div>
  );
};
