import React from 'react';

import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  FloatingActionButton,
  ICON_VARIANT,
  Icon,
} from 'shared-frontend-components/dist/atoms';

import { useJobsMutation } from '../../../config/api';
import { LG_SCREEN } from '../../../config/globalConstants';
import { buildUrlForBookmarkItem, pushDataLayer } from '../../../libs/helpers/helpers';
import { DELETE_FAVOR_JOB_MUTATION, GET_FAVOR_JOBS_QUERY } from '../bookmarkQueries';
import { BookmarkItemImage } from './BookmarkItemImage';

import './_bookmarkItem.scss';
import { JobsBookmarkBody } from './bookamarkItemBodyTypes/JobsBookmarkBody';

const verticalPath = {
  jobs: 'jobs',
};

export const BookmarkItem = ({ bookmarkItem, ...props }) => {
  const [deleteFavorJob, { errors: jobErrors }] = useJobsMutation(DELETE_FAVOR_JOB_MUTATION, {
    variables: {
      jobReferenceNumber: bookmarkItem.referencenumber,
    },
    update: (cache, { data }) => updateFunctionAfterDeleteJob(cache, { data }),
  });

  if (jobErrors) {
    console.error({ jobErrors });
  }

  const updateFunctionAfterDeleteJob = (cache, { data }) => {
    const { deleteFavorJob } = data || {};

    if (!deleteFavorJob) return;

    const { getFavoredJobs } = cache.readQuery({
      query: GET_FAVOR_JOBS_QUERY,
    });
    const newData = getFavoredJobs.filter((item) => item.job.referencenumber !== deleteFavorJob);
    cache.writeQuery({
      query: GET_FAVOR_JOBS_QUERY,
      data: { getFavoredJobs: newData },
    });
  };

  const onDeleteHandle = (event) => {
    event.stopPropagation();
    event.preventDefault();

    pushDataLayer({
      category: 'Userprofile',
      event: 'removeBookmark',
      objectId: bookmarkItem.referencenumber,
      objectVertical: 'jobs',
    });

    return deleteFavorJob();
  };

  const redirectProps =
    window.innerWidth > LG_SCREEN ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  const detailPageUrl = buildUrlForBookmarkItem({
    vertical: verticalPath.jobs,
    itemId: bookmarkItem.id,
  });

  const imageContainerClass = `bookmark-item-image-container jobs-image-container`;

  return (
    <li className="bookmark-item" {...props}>
      <a className="bookmark-item-link" href={detailPageUrl} {...redirectProps}>
        <div className="bookmark-item-image-wrapper">
          <div className={imageContainerClass}>
            <BookmarkItemImage bookmarkItem={bookmarkItem} />
          </div>
        </div>
        <JobsBookmarkBody bookmarkItem={bookmarkItem} />
        <div className="bookmark-item-delete-button-container">
          <FloatingActionButton
            variant={BUTTON_VARIANT.SECONDARY}
            size={BUTTON_SIZE.SMALL}
            onClick={onDeleteHandle}
            margin="10px"
          >
            <Icon variant={ICON_VARIANT.BIN} size={{ width: '24px', height: '24px' }} />
          </FloatingActionButton>
        </div>
      </a>
    </li>
  );
};
