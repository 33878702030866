import React, { createContext, useMemo, useState, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';

import { pageLoadEvent, getCurrentQuery, pushDataLayer } from '../helpers/helpers';
import { answerCustomChallenge, sendVerifyCodeToControlAPI } from '../authorization';

const SIGNED_IN = 'signedIn';
const FETCHED_USER = 'fetchedUser';

export const UserContext = createContext({});

export const UserContextProvider = ({ children, ...rest }) => {
  const [cognitoUser, setCognitoUser] = useState({});
  const [authState, setAuthState] = useState('');
  const [saveSearchUserSuccess, setSaveSearchUserSuccess] = useState(true);
  const { push } = useHistory();

  const tryFetchUser = async (willRedirect = false) => {
    try {
      const localStorageUser = await Auth.currentAuthenticatedUser();
      setCognitoUser(localStorageUser);
      await Auth.currentSession();
      !willRedirect && setAuthState(SIGNED_IN);

      return true;
    } catch {
      setAuthState('signIn');

      return false;
    }
  };

  const isLoggedUser = authState === SIGNED_IN;
  const isUserFetched = authState === FETCHED_USER;
  const isLoading = authState === '';

  const extendedPageLoadEvent = (data) => pageLoadEvent({ ...data, userLoggedIn: isLoggedUser });

  const handleSaveSearchToken = async () => {
    const query = getCurrentQuery(window.location.search);
    const { token } = query;
    const { username } = JSON.parse(localStorage.getItem('cognitoUser')) || {};

    try {
      const codeVerificationResponse = await sendVerifyCodeToControlAPI(username, token);
      if (codeVerificationResponse.success) {
        localStorage.setItem(
          'userTokens',
          JSON.stringify(codeVerificationResponse.authenticationResult),
        );
      } else {
        return setSaveSearchUserSuccess(false);
      }

      setAuthState(FETCHED_USER);
    } catch (error) {
      setSaveSearchUserSuccess(false);
    }
  };

  const handleExistingToken = async () => {
    const query = getCurrentQuery(window.location.search);

    try {
      const pageRef = localStorage.getItem('pageReferrer');
      const shouldRedirect = pageRef && pageRef !== 'null' && pageRef.indexOf('authorization') < 0;
      const successfulRefresh = await tryFetchUser(shouldRedirect);

      const gtmCategory = 'login';
      const gtmEvent = 'clickOnLoginLink';

      pushDataLayer({ event: gtmEvent, category: gtmCategory });

      if (!successfulRefresh) {
        const challengeResponse = await answerCustomChallenge(query.token);

        if (!challengeResponse) {
          return push('/authorization?expired=true');
        }

        await tryFetchUser(shouldRedirect);
      }

      if (shouldRedirect) {
        window.location.replace(pageRef);
      } else {
        push('/myprofile');
      }
    } catch (error) {
      console.log('failed to answer custom challange', error);
      if (push) {
        push('/authorization');
      }
    }
  };

  useEffect(() => {
    const query = getCurrentQuery(window.location.search);
    const { token } = query;
    const isAuthorization = window.location.pathname === '/authorization';
    const isSaveSearch = window.location.pathname === '/savesearch';

    if (token && isAuthorization) {
      handleExistingToken();
    } else if (isSaveSearch) {
      token ? handleSaveSearchToken() : push('/');
    } else {
      tryFetchUser();
    }
    // eslint-disable-next-line
  }, []);

  const value = useMemo(
    () => ({
      cognitoUser,
      setCognitoUser,
      isLoggedUser,
      authState,
      setAuthState,
      isLoading,
      isUserFetched,
      tryFetchUser,
      extendedPageLoadEvent,
      saveSearchUserSuccess,
      setSaveSearchUserSuccess,
    }),
    // eslint-disable-next-line
    [cognitoUser, authState, isLoggedUser, isUserFetched, isLoading, saveSearchUserSuccess],
  );

  const { Provider } = UserContext || {};

  return (
    <Provider {...rest} value={value}>
      {children}
    </Provider>
  );
};
