import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PageLoader } from 'shared-frontend-components/dist/index';

import { realEstateUrl, jobsUrl } from '../../config/appConfig';
import { getCurrentQuery } from '../../libs/helpers/helpers';
import { appendUrlParams } from '../../libs/helpers/stringHelpers';
import { useJobsMutation, useRealestateMutation } from '../../config/api';
import { RE_UNSUBSCRIBE_SAVE_SEARCH, JOBS_UNSUBSCRIBE_SAVE_SEARCH } from './queries';

const AUSTRIA_OSM_ID = 16239;

const SAVE_SEARCH_UNSUBSCRIBE_SUCCESS_PARAMS = 'notification=savesearchunsubscribe&status=success';
const SAVE_SEARCH_UNSUBSCRIBE_ERROR_PARAMS = 'notification=savesearchunsubscribe&status=error';

const verticalToListingPageUrl = {
  jobs: `${jobsUrl}/search/${AUSTRIA_OSM_ID}`,
  immobilien: `${realEstateUrl}/search/${AUSTRIA_OSM_ID}/rent`,
};

export const UnsubscribePage = (props) => {
  const { push } = useHistory();
  const { id, sh: secureHash, v: vertical, type } = getCurrentQuery(window.location.search);

  const [reUnsubscribeSaveSearch, { error: reError, data: reData }] = useRealestateMutation(
    RE_UNSUBSCRIBE_SAVE_SEARCH,
    {
      variables: {
        id: parseInt(id),
        secureHash: secureHash,
      },
    },
  );

  const [jobsUnsubscribeSaveSearch, { error: jobsError, data: jobsData }] = useJobsMutation(
    JOBS_UNSUBSCRIBE_SAVE_SEARCH,
    {
      variables: {
        id: parseInt(id),
        secureHash: secureHash,
      },
    },
  );

  const mutationMapping = {
    savesearch: {
      immobilien: reUnsubscribeSaveSearch,
      jobs: jobsUnsubscribeSaveSearch,
    },
  };

  const startUnsubscribeEvent = async () => {
    const mutation = mutationMapping[type][vertical];

    await mutation();
  };

  useEffect(() => {
    if (!id || !secureHash || !vertical || !type) {
      return push('/');
    }

    startUnsubscribeEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const error = reError || jobsError;
    const data = reData || jobsData;

    if (error && vertical) {
      const baseUrl = verticalToListingPageUrl[vertical];
      const url = appendUrlParams(baseUrl, SAVE_SEARCH_UNSUBSCRIBE_ERROR_PARAMS);

      window.location.replace(url);
    }

    if (data && data.unsubscribeSaveSearch && data.unsubscribeSaveSearch.searchUrl) {
      const { searchUrl } = data.unsubscribeSaveSearch;
      const url = appendUrlParams(searchUrl, SAVE_SEARCH_UNSUBSCRIBE_SUCCESS_PARAMS);

      window.location.replace(url);
    }
  }, [jobsData, jobsError, push, reData, reError, vertical]);

  return <PageLoader {...props} />;
};
