import React from 'react';
import { Route } from 'react-router-dom';

import { BookmarksList } from '../bookmarksList/BookmarksList';
import { SearchAgentList } from '../searchAgentList/SearchAgentList';

export const ProfileRoutes = () => (
  <>
    <Route path="/myprofile/searchagents" component={SearchAgentList} />
    <Route path="/myprofile/bookmarks" component={BookmarksList} />
  </>
);
