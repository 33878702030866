import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import { UserContext } from '../../libs/context';
import {
  getAndParseLocalStorageItem,
  removeStoredItem,
} from '../../libs/helpers/localStorageHelpers';
import { appendUrlParams } from '../../libs/helpers/stringHelpers';
import { signOut } from '../../libs/authorization';
import { useJobsMutation, useRealestateMutation } from '../../config/api';
import { HARDCODED_REALESTATE_URL_PATH } from '../../config/appConfig';
import { INSERT_SEARCH, SAVE_SEARCH } from './queries';

const SEARCH_TO_SAVE = 'searchToSave';
const ACTIONS_TO_DO = 'actionsToDo';
const SAVE_SEARCH_SUCCESS_PARAMS = 'notification=savesearch&status=success';
const SAVE_SEARCH_ERROR_PARAMS = 'notification=savesearch&status=error';
const SAVE_SEARCH_ALREADY_EXISTS_PARAMS = 'notification=savesearch&status=already_exists';
const ALREADY_EXISTS_CODE = 'ALREADY_EXISTS';

export const AuthorizedActionsRoute = (props) => {
  const [saved, setSaved] = useState(false);
  const { isUserFetched, saveSearchUserSuccess, setSaveSearchUserSuccess } =
    useContext(UserContext);

  const searchToSave = getAndParseLocalStorageItem(SEARCH_TO_SAVE);
  const { variables: reVariables } = searchToSave || {};

  const actionsToDo = getAndParseLocalStorageItem(ACTIONS_TO_DO);
  const { saveSearch: saveSearchActionToDo } = actionsToDo || {};
  const { variables: jobsVariables } = saveSearchActionToDo || {};

  const [reSaveSearch, { error: reSaveSearchError, called: reSaveSearchCalled }] =
    useRealestateMutation(INSERT_SEARCH, {
      ...searchToSave,
      onError: () => {},
    });

  const [jobsSaveSearch, { error: jobsSaveSearchError, called: jobsSaveSearchCalled }] =
    useJobsMutation(SAVE_SEARCH, {
      variables: {
        ...jobsVariables,
      },
      onError: () => {},
    });

  const redirectToSaveSearchUrl = () => {
    const { searchUrl = '' } = reVariables || jobsVariables || {};
    let url;

    if (reVariables) {
      const searchUrlObject = new URL(searchUrl);
      const { pathname, search } = searchUrlObject;
      url = `${pathname}${search}`;
    } else {
      url = searchUrl;
    }

    if (!url || url.length === 0 || searchUrl.length === 0) {
      url = `${HARDCODED_REALESTATE_URL_PATH}`;
    }

    let saveSearchParams = SAVE_SEARCH_SUCCESS_PARAMS;

    const errorOnInsertSaveSearch =
      (reSaveSearchCalled && reSaveSearchError) ||
      (jobsSaveSearchCalled && jobsSaveSearchError) ||
      !saveSearchUserSuccess;

    if (errorOnInsertSaveSearch) {
      const error = jobsSaveSearchError || reSaveSearchError;
      const { graphQLErrors = [] } = error || {};
      const { extensions: { code } = {} } = graphQLErrors[0] || {};
      saveSearchParams =
        ALREADY_EXISTS_CODE === code ? SAVE_SEARCH_ALREADY_EXISTS_PARAMS : SAVE_SEARCH_ERROR_PARAMS;
    }

    removeStoredItem(SEARCH_TO_SAVE);
    removeStoredItem(ACTIONS_TO_DO);

    url = appendUrlParams(url, saveSearchParams);

    window.location.replace(url);
  };

  const startSaveSearchAction = async () => {
    if (!reVariables && !jobsVariables) {
      await signOut();

      return setSaveSearchUserSuccess(false);
    }

    if (reVariables) {
      await reSaveSearch();
    }
    if (jobsVariables) {
      await jobsSaveSearch();
    }

    await signOut();

    setSaved(true);
  };

  useEffect(() => {
    if (isUserFetched) {
      startSaveSearchAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserFetched]);

  useEffect(() => {
    if (saved || !saveSearchUserSuccess) {
      redirectToSaveSearchUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved, saveSearchUserSuccess]);

  return <Route {...props} />;
};
