import React from 'react';

export const ListCommonHeader = ({ totalCount, title, numberTitle }) => {
  return (
    <div className="bookmarks-list-header">
      <span className="bookmarks-list-title">{title}</span>
      {!!totalCount && <span className="booksmarks-list-results">{numberTitle}</span>}
    </div>
  );
};
