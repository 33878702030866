import { createHmac, createHash } from 'crypto';

import { API_REQUEST_KEY } from '../../config/appConfig';

export const generateSecurityHash = (data) => {
  const stringData = JSON.stringify(data);

  return createHmac('sha256', API_REQUEST_KEY).update(stringData).digest('hex');
};

export const sha256 = (data) => createHash('sha256').update(data).digest('hex');
