import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ListCommonHeader, ListCommonSkeletonLoader, ListCommonWarning } from '../../components';
import { useJobsQuery } from '../../config/api';
import { UserContext } from '../../libs/context';
import { sortBookmarksByDate } from '../../libs/helpers/helpers';
import { SearchAgentItem } from './searchAgentItem/SearchAgentItem';
import { SEARCHES } from './searchAgentQueries';

import './_searchAgentList.scss';

export const SearchAgentList = () => {
  const { t } = useTranslation('common');
  const { isLoggedUser, extendedPageLoadEvent: pageLoadEvent } = useContext(UserContext);

  const {
    data,
    loading: isLoading,
    errors,
  } = useJobsQuery(SEARCHES, {
    skip: !isLoggedUser,
  });

  useEffect(() => {
    pageLoadEvent({
      pageType: 'service',
      serviceFeature: 'searchagent',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errors) {
    console.error({ error: errors });
  }

  const { searches = [] } = data || {};

  const searchesModifiedDate = searches.map((search) => {
    const { createdAt } = search || {};

    return { ...search, createdAt: new Date(createdAt).valueOf() };
  });

  const searchAgentList = [...searchesModifiedDate];

  const showNoItemsWarning = !searchAgentList.length && !isLoading;
  const totalCount = searchAgentList.length;

  return (
    <div>
      <ListCommonHeader
        title={t('profile.searchAgents')}
        numberTitle={t('profile.searchAgentsNumber', { count: totalCount })}
        totalCount={totalCount}
      />
      {searchAgentList &&
        (isLoading ? (
          <ListCommonSkeletonLoader />
        ) : (
          <ul className="search-agent-list">
            {sortBookmarksByDate(searchAgentList).map((searchAgentItem) => (
              <li key={searchAgentItem.id}>
                <SearchAgentItem searchAgentItem={searchAgentItem} />
              </li>
            ))}
          </ul>
        ))}
      <ListCommonWarning show={showNoItemsWarning} noItems={t('profile.noSearchAgentItems')} />
    </div>
  );
};
