import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'shared-frontend-components/dist/atoms';

import { UserContext } from '../../libs/context';

import './_aboutPage.scss';

export const About = () => {
  const { extendedPageLoadEvent: pageLoadEvent } = useContext(UserContext);
  const { t } = useTranslation('common');

  useEffect(() => {
    pageLoadEvent({
      pageType: 'imprint',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="about-section">
      <article>
        <header className="entry-header">
          <h1 className="entry-title">{t('about.title')}</h1>
        </header>
        <div className="entry-content">
          <p>Informationen gem. § 14 UGB, § 5 ECG, § 25 Mediengesetz</p>
          <p>
            <Link href="https://www.finden.at/">
              <b>www.finden.at</b>
            </Link>{' '}
            ist ein Angebot der STANDARD Verlagsgesellschaft m.b.H.
          </p>
          <h3 className="about-section-title">Medieninhaber, Herausgeber und Redaktion:</h3>
          <p>
            STANDARD Verlagsgesellschaft m.b.H.
            <br />
            Vordere Zollamtsstraße 13, A-1030 Wien
            <br />
            Tel.: +43-1-53170-0
          </p>
          <h3 className="about-section-title">Kontakt:</h3>
          <p>
            <b>Christoph Brammertz</b>
            <br />
            Team Lead Jobs
            <br />
            <Link href="mailto:christoph.brammertz@finden.at">christoph.brammertz@finden.at</Link>
            <br />
            <Link href="tel:+43153170653">+43 1 53170 653</Link>
          </p>
          <p>
            <b>Yvonne Fischer</b>
            <br />
            Head of Sales STANDARD Marketplaces
          </p>
          <h3 className="about-section-title">Firmenbuchnummer:</h3>
          <p>133444t</p>
          <h3 className="about-section-title">Firmenbuchgericht:</h3>
          <p>Handelsgericht Wien</p>
          <h3 className="about-section-title">Unternehmensgegenstand:</h3>
          <p>
            Betrieb eines Medienunternehmens, insbesondere die Herausgabe der Tageszeitung DER
            STANDARD sowie der Betrieb eines Online-Nachrichtendienstes
          </p>
          <h3 className="about-section-title">UID-Nr.:</h3>
          <p>ATU 51142008</p>
          <h3 className="about-section-title">Aufsichtsbehörde gemäß E-Commerce-Gesetz:</h3>
          <p>Magistratisches Bezirksamt für den 3. Bezirk</p>
          <h3 className="about-section-title">Kammer / Berufsverband:</h3>
          <p>WKO, VÖZ</p>
          <h3 className="about-section-title">Anwendbare Vorschriften:</h3>
          <p>
            Österr. Gewerbeordnung{' '}
            <Link href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10007517">
              (Bundesgesetzblatt)
            </Link>
          </p>
          <h3 className="about-section-title">Streitschlichtung:</h3>
          <p>
            Alternative Streitbeilegung gem. § 19 (1) AStG:
            <br />
            Internet Ombudsmann:{' '}
            <Link href="http://www.ombudsmann.at"> http://www.ombudsmann.at</Link>
          </p>
          <p>
            Online Streitbeilegung gem. Art 14 Abs 1 ODR-VO:
            <br />
            Internetplattform zur Online-Beilegung von Streitigkeiten der EU-Kommission:{' '}
            <Link href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</Link>
          </p>
          <p>
            Kontakt: <Link href="mailto:info@finden.at">info@finden.at</Link>
          </p>
          <h3 className="about-section-title" id="agb">
            AGB:
          </h3>
          <p>
            Es gilt Punkt VIII. der folgenden Allgemeinen Geschäftsbedingungen.
            <br />
            <Link href="https://about.derstandard.at/agb">STANDARD AGB</Link>
          </p>
        </div>
      </article>
    </section>
  );
};
