//try to get localStorage item, if it doesn't exist returns empty object
export const getAndParseLocalStorageItem = (item) => {
  const result = localStorage.getItem(item);
  if (result) {
    return JSON.parse(result);
  }
};

export const saveItemToLocalStorage = (key, item) =>
  localStorage.setItem(key, JSON.stringify(item));

export const removeStoredItem = (item) => localStorage.removeItem(item);

// if item doesn't exist in localStorage then it will be created
export const enrichStoredItem = (item, enrichBy) => {
  const result = getAndParseLocalStorageItem(item) || {};
  const updatedItem = {
    ...result,
    ...enrichBy,
  };

  localStorage.setItem(item, JSON.stringify(updatedItem));
};

export const devalueItem = (item, devalueBy) => {
  const result = getAndParseLocalStorageItem(item);
  let filteredItem = result;
  delete filteredItem[devalueBy];

  localStorage.setItem(item, JSON.parse(filteredItem));
};
