import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PageLoader } from 'shared-frontend-components/dist/index';

import { dataProtectionUrl, publicUrl } from './config/appConfig';
import { AuthorizedActionsRoute, AuthorizedRoute, NonAuthorizedRoute } from './modules';
import { About, AuthorizationPage, NotFound, Profile, UnsubscribePage } from './pages';

const createExternalUrlRedirect = (externalUrl) => () => {
  window.location.href = externalUrl;

  return null;
};

export const Routes = () => (
  <Switch>
    <Route exact path="/" render={createExternalUrlRedirect('https://finden.at')} />
    <Route
      exact
      path={`${publicUrl}/dataprotection`}
      render={createExternalUrlRedirect(dataProtectionUrl)}
    />
    <Route path={`${publicUrl}/imprint`} component={About} />
    <AuthorizedRoute path={`${publicUrl}/myprofile`} component={Profile} />
    <NonAuthorizedRoute path="/authorization" component={AuthorizationPage} />
    <AuthorizedActionsRoute path="/savesearch" component={PageLoader} />
    <Route path="/unsubscribe" component={UnsubscribePage} />
    <Route path="*" component={NotFound} />
  </Switch>
);
