import React from 'react';

import { ProfileContent } from './ProfileContent';
import { ProfileRoutes } from './ProfileRoutes';

import './_profileContainer.scss';

export const ProfileContainer = () => (
  <div className="finden-profile">
    <ProfileContent>
      <ProfileRoutes />
    </ProfileContent>
  </div>
);
