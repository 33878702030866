import React from 'react';
import { useTranslation } from 'react-i18next';

export const SearchAgentBody = ({ searchAgentItem }) => {
  const { t } = useTranslation('common');
  const { searchObject: parsedSearchObject } = searchAgentItem;
  // OSM ID and type is destructured so it's not rendered in the map, do not delete
  const {
    osmId,
    title,
    salaryMin,
    salaryMax,
    locationName,
    __typename,
    geoDataId,
    geoDataString,
    forSale,
    price,
    roomCount,
    livingSpace,
    amenityOptions,
    categories,
    amenities,
    openspace,
    commissions,
    ...restSearchObject
  } = parsedSearchObject || {};

  const jobFilters = Object.keys(restSearchObject).map((prop, index) => {
    if (prop && Array.isArray(restSearchObject[prop])) {
      return (
        prop.length && (
          <div key={index}>
            {restSearchObject[prop].map((item) => (
              <p key={item}>{item}</p>
            ))}
          </div>
        )
      );
    }

    return prop && <p key={index}>{restSearchObject[prop]}</p>;
  });

  const renderTitle = (heading) => (heading ? <h5>{heading}</h5> : <h5>-</h5>);

  return (
    <div>
      {renderTitle(title)}
      {(locationName && <p>{locationName}</p>) || <p>{t('common.austria')}</p>}
      {jobFilters}
      {salaryMax > 0 && salaryMin >= 0 && <p>{`${salaryMin} - ${salaryMax}€`}</p>}
    </div>
  );
};
