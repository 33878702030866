import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PinLocation } from '../../../../assets/img/pin-location.svg';

import './_jobsBookmarkBody.scss';

export const JobsBookmarkBody = ({ bookmarkItem, ...props }) => {
  const { company, title, city } = bookmarkItem;
  const { t } = useTranslation('common');

  const cityString = city || t('profile.bookmarks.noLocation');

  return (
    <div className="bookmark-item-body" {...props}>
      <div className="bookmark-item-title bookmark-item-title-jobs">{company}</div>
      <div className="bookmark-item-description bookmark-item-description-jobs">{title}</div>
      <div className="bookmark-item-detail">
        <div className="bookmark-item-location">
          <PinLocation className="bookmark-item-pin-location" />
          {cityString}
        </div>
      </div>
    </div>
  );
};
