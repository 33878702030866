import React from 'react';

import './_bookmarkListLoader.scss';

export const ListCommonSkeletonLoader = ({ image }) => {
  const items = [...Array(10).keys()].map((item) => (
    <li key={item} className="bookmark-loader-item">
      {image && <div className="bookmark-loader-item-image" />}
      <div className="bookmark-loader-item-body">
        <div className="bookmark-loader-item-title" />
        <div className="bookmark-loader-item-description" />
        <div className="bookmark-loader-item-location" />
      </div>
      <div className="bookmark-loader-item-button" />
    </li>
  ));

  return <>{items}</>;
};
