import React, { useState, useMemo, createContext } from 'react';

export const AppContext = createContext({});

export const AppContextProvider = ({ children, ...rest }) => {
  const [pageReferrer, setPageReferrer] = useState(null);

  const value = useMemo(
    () => ({
      pageReferrer,
      setPageReferrer,
    }),
    [pageReferrer],
  );

  const { Provider } = AppContext || {};

  return (
    <Provider value={value} {...rest}>
      {children}
    </Provider>
  );
};
