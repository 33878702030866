import React, { useRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  UserLoggedInDropDownActive,
  LoginSignedIn,
  IconDst,
  LinkWithIcon,
  LoginDropDown,
} from 'shared-frontend-components/dist/index';
import { useToggle } from 'shared-frontend-components/dist/hooks';

import { ReactComponent as LoggedInUser } from '../../assets/img/logged-in-user.svg';
import { ReactComponent as ArrowRightBottom } from '../../assets/img/arrow-right-bottom.svg';
import { ReactComponent as Briefcase } from '../../assets/img/briefcase-outline.svg';
import { ReactComponent as Home } from '../../assets/img/home-outline.svg';
import { signOut } from '../../libs/authorization';
import { UserContext } from '../../libs/context';
import { AuthorizationRenderer } from '../authorization/AuthorizationRenderer';

import './_headerRight.scss';

export const HeaderRight = () => {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const node = useRef();
  const [isUserMenuOpen, toggleUserMenu] = useToggle();
  const { cognitoUser, isLoggedUser } = useContext(UserContext);
  const { username } = cognitoUser || {};

  const profileMenuItems = [
    {
      url: '/immobilien/suche/oesterreich/mieten-wohnung',
      title: 'Immobilien',
      icon: Home,
      type: 'externalLink',
    },
    {
      url: '/immobilien/merkliste',
      title: t('profile.bookmarks.title'),
      icon: ArrowRightBottom,
      type: 'externalLink',
    },
    // {
    //   url: '/immobilien/searchagents',
    //   title: t('profile.searchAgent'),
    //   icon: ArrowRightBottom,
    //   type: 'externalLink',
    // },
    {
      title: 'Jobs',
      url: '/jobs/search/16239',
      icon: Briefcase,
      type: 'externalLink',
    },
    {
      url: '/myprofile/bookmarks',
      title: t('profile.bookmarks.title'),
      icon: ArrowRightBottom,
      active: pathname === '/myprofile/bookmarks',
      type: 'routerLink',
    },
    {
      url: '/myprofile/searchagents',
      title: t('profile.searchAgent'),
      icon: ArrowRightBottom,
      active: pathname === '/myprofile/searchagents',
      type: 'routerLink',
    },
    {
      title: t('profile.signOut'),
      type: 'click',
    },
  ];

  const enabledLinks = profileMenuItems.filter((item) => !item.disabled);

  const handleSignOutClick = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error(error);
    }
  };

  const renderItems = ({ url, title, icon: Icon, type, active }) => {
    switch (type) {
      case 'click': {
        return (
          <LinkWithIcon
            isActive={active}
            link={title}
            icon={Icon && <IconDst renderIcon={Icon && <Icon />} iconSize="small-icon" />}
            renderElement={(className, link, icon) => (
              <a href="/" onClick={handleSignOutClick} className={className}>
                {icon}
                {link}
              </a>
            )}
          />
        );
      }
      case 'routerLink': {
        return (
          <LinkWithIcon
            isActive={active}
            link={title}
            icon={<IconDst renderIcon={Icon && <Icon />} iconSize="small-icon" />}
            renderElement={(className, link, icon) => (
              <Link to={url} className={className} style={{ width: 'unset' }}>
                {icon}
                {link}
              </Link>
            )}
          />
        );
      }
      case 'externalLink': {
        return (
          <LinkWithIcon
            isActive={active}
            link={title}
            icon={<IconDst renderIcon={Icon && <Icon />} iconSize="small-icon" />}
            renderElement={(className, link, icon) => (
              <a href={url} title={title} className={className}>
                {icon}
                {link}
              </a>
            )}
          />
        );
      }
      default: {
        return (
          <LinkWithIcon
            isActive={active}
            link={title}
            icon={<IconDst renderIcon={Icon && <Icon />} iconSize="small-icon" />}
            renderElement={(className, link, icon) => (
              <span className={className}>
                {icon}
                {link}
              </span>
            )}
          />
        );
      }
    }
  };

  const renderUserMenu = () => (
    <UserLoggedInDropDownActive
      renderLoginIcon={
        <LoginSignedIn
          icon={<IconDst renderIcon={<LoggedInUser />} iconSize="normal-icon" />}
          selected={isUserMenuOpen}
          onClick={toggleUserMenu}
          project="immobilien"
        />
      }
      renderDropDown={
        isUserMenuOpen && (
          <LoginDropDown
            project="immobilien"
            userEmail={username}
            links={enabledLinks}
            renderLink={renderItems}
            onClick={() => toggleUserMenu(false)}
          />
        )
      }
    />
  );

  return (
    <div className="finden-header-right">
      <div ref={node}>
        <AuthorizationRenderer
          authorized={isLoggedUser}
          renderAuthorized={renderUserMenu}
          renderNonAuthorized={() => (
            <Link className="login-link" to="/authorization">
              {t('authorization.loginHeader')}
            </Link>
          )}
        />
      </div>
    </div>
  );
};
