import gql from 'graphql-tag';

export const INSERT_SEARCH = gql`
  mutation ($searchUrl: String!, $filter: FilterInput!) {
    reSaveSearch: insertSearch(searchUrl: $searchUrl, filter: $filter)
  }
`;

export const SAVE_SEARCH = gql`
  mutation saveSearch($searchUrl: String!, $filter: FilterInput!) {
    jobsSaveSearch: saveSearch(searchUrl: $searchUrl, filter: $filter)
  }
`;
