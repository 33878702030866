import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Footer } from 'shared-frontend-components/dist/index';

import { ReactComponent as Logo } from '../../assets/img/finden-logo-dark.svg';
import {
  FINDEN_CONTACT_EMAIL,
  baseUrl,
  dataProtectionUrl,
  jobsUrl,
  publicUrl,
  realEstateUrl,
} from '../../config/appConfig';

import { openPrivacyManagerModal } from '../../libs/cookieScript';
import './_footer.scss';

export const SharedFooter = ({ hidden }) => {
  const { t } = useTranslation('common');

  if (hidden) {
    return null;
  }

  const renderFooterLink = ({ text, url }) => (
    <a href={url} className="footer-section-link">
      {text}
    </a>
  );

  const renderFooterButton = ({ text, onClick }) => (
    <button onClick={onClick} className="footer-section-link">
      {text}
    </button>
  );

  const renderFooterItem = ({ text, url, onClick }) => {
    const isLink = !!url;

    return isLink ? renderFooterLink({ text, url }) : renderFooterButton({ text, onClick });
  };

  const currentYear = new Date().getFullYear();

  return (
    <Footer
      renderLogo={() => {
        return (
          <div className="footer-section-left-side">
            <Link to={`${publicUrl}`}>
              <Logo className="footer-section-logo" />
            </Link>
          </div>
        );
      }}
      links={[
        {
          text: t('header.about'),
          url: `${baseUrl}/imprint`,
        },
        {
          text: t('header.dataprotection'),
          url: dataProtectionUrl,
        },
        {
          text: FINDEN_CONTACT_EMAIL,
          url: `mailto:${FINDEN_CONTACT_EMAIL}`,
        },
        {
          text: t('header.realEstates'),
          url: realEstateUrl,
        },
        {
          text: t('header.jobs'),
          url: jobsUrl,
        },
        {
          text: t('header.privacyManager'),
          onClick: openPrivacyManagerModal,
        },
      ]}
      renderLink={renderFooterItem}
      copyrightText={t('header.copyright', { year: currentYear })}
    />
  );
};
