import {
  ENVIRONMENT,
  SOURCEPOINT_COOKIE_CONSENT_ACCOUNT_ID,
  SOURCEPOINT_COOKIE_CONSENT_BASE_ENDPOINT,
  SOURCEPOINT_COOKIE_CONSENT_PRIVACY_MANAGER_ID,
} from '../config/appConfig';

const createScript = (innerHTML) => {
  const script = document.createElement('script');
  if (innerHTML) {
    script.innerHTML = innerHTML;
  }

  return script;
};

const appendScriptsInOrder = (scripts) => {
  if (document && document.head) {
    scripts.forEach((script, i) => document.head.insertBefore(script, document.head.childNodes[i]));
  }
};

export const appendCookieConsentScript = () => {
  const configurationScript = createScript(`window.dataLayer = window.dataLayer || [];
window._sp_ = {
  config: {
    accountId: ${SOURCEPOINT_COOKIE_CONSENT_ACCOUNT_ID},
    baseEndpoint: '${SOURCEPOINT_COOKIE_CONSENT_BASE_ENDPOINT}',
    consentLanguage: 'de',
  }
}`);

  const urlScript = createScript();
  urlScript.async = true;
  urlScript.src = `${SOURCEPOINT_COOKIE_CONSENT_BASE_ENDPOINT}/wrapperMessagingWithoutDetection.js`;
  const isDevEnv = ENVIRONMENT === 'development';
  const scripts = [configurationScript, !isDevEnv && urlScript].filter(Boolean);

  appendScriptsInOrder(scripts);
};

export const openPrivacyManagerModal = () =>
  window._sp_.loadPrivacyManagerModal(SOURCEPOINT_COOKIE_CONSENT_PRIVACY_MANAGER_ID);
