import { ApolloClient, from, createHttpLink, useQuery, useMutation } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import fetch from 'unfetch';

import { JOBS_DATA_WAREHOUSE_API_URL, REAL_ESTATE_DATA_WAREHOUSE_API_URL } from '../appConfig.js';
import { hashMiddleware, tokenMiddleware } from '../middlewares';
import { sha256 } from '../../libs/helpers/securityHelpers';

const onErrorHandle = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${JSON.stringify(
          extensions,
        )}`,
      ),
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const httpLinkJobs = createHttpLink({ uri: JOBS_DATA_WAREHOUSE_API_URL, fetch });

const httpLinkRealEstate = createHttpLink({ uri: REAL_ESTATE_DATA_WAREHOUSE_API_URL, fetch });

export const jobsDataWarehouseClient = new ApolloClient({
  link: from([
    onErrorHandle,
    hashMiddleware,
    createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }),
    tokenMiddleware,
    httpLinkJobs,
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => object.id,
  }),
});

export const useJobsQuery = (query, config) =>
  useQuery(query, {
    ...config,
    client: jobsDataWarehouseClient,
  });

export const useJobsMutation = (mutation, config) =>
  useMutation(mutation, {
    ...config,
    client: jobsDataWarehouseClient,
  });

export const realeastateDataWarehouseClient = new ApolloClient({
  link: from([
    onErrorHandle,
    hashMiddleware,
    createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }),
    tokenMiddleware,
    httpLinkRealEstate,
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => object.id,
  }),
});

export const useRealestateQuery = (query, config) =>
  useQuery(query, {
    ...config,
    client: realeastateDataWarehouseClient,
  });

export const useRealestateMutation = (mutation, config) =>
  useMutation(mutation, {
    ...config,
    client: realeastateDataWarehouseClient,
  });
