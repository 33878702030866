/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const WWW_CHECK_STRING = '://www';
const URL_WITHOUT_WWW = '://finden.at';
const URL_WITH_WWW = `${WWW_CHECK_STRING}.finden.at`;

export const checkTypedLocationLength = (text) => text && text.length >= 1;
export const checkTypedCompaniesLength = (text) => text && text.length >= 1;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const wordRegex =
  /^[A-Za-z-ÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßẞœŒçÇ\s\uFEFF]+$/;

export const textareaRegex =
  /^[0-9.,@#$&()%?!/+=_\-:A-Za-z-ÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßẞœŒçÇ\s\uFEFF]+$/;

export const wordAndNumberRegex =
  /^[A-Za-z-ÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßẞœŒçÇ\s0-9/]+$/;

export const phoneNumberRegex = /[+]?[0-9]/;

export const dateRegex =
  /^(?:[1-9]|[0-2][0-9]|3[0-1])\s?([\/.-])\s?(?:[0-9]|0[0-9]|1[0-2])\s?\1\s?\d{4}$/; //regex to match format DD/MM/YYYY ,calculation of correct date is handled inside datepicker

export const invalidDateRegex =
  /^(?:[1-9]|[0-2][0-9]|3[0-1])\s?([\/.-])\s?(?:[0-9]|0[0-9]|1[0-2])\s?\1\s?\d{0,3}$/; //regex to match invalid date;

export const numberRegex = /^[0-9]+$/;

export const minInputLengthCheck = (input, requiredLength) => {
  if (!input) {
    return false;
  }
  if (!input.replace) {
    return false;
  }

  const inputWithoutSpaces = input.replace(/\s/gi, '');

  return inputWithoutSpaces.length >= requiredLength;
};

export const INVALID_DATE = 'Invalid Date';
export const STANDARD_DATE_PATTERN = 'YYYY-M-D';
export const DATE_PATTERN = 'DD. MM. YYYY';
export const DATE_PATTERN_SIMPLE_PARSE = 'D.M.YYYY';

export const customFormatDate = (date) => {
  const isInvalidDate = invalidDateRegex.test(date);

  if (isInvalidDate) {
    return date;
  }

  const parsedDate = dayjs(new Date(date));

  if (parsedDate === INVALID_DATE) {
    return date;
  }

  const formattedDate = parsedDate.format(DATE_PATTERN);

  return formattedDate;
};

export const customParseDate = (date) => {
  const dateToStandardPattern = dayjs(date, DATE_PATTERN_SIMPLE_PARSE).format(
    STANDARD_DATE_PATTERN,
  );

  const dateFormatCheck = dateRegex.test(date);

  if (dateToStandardPattern === INVALID_DATE || !dateFormatCheck) {
    return null;
  }

  return new Date(dateToStandardPattern);
};

export const extendUrlToFullVersion = (url) => {
  if (!url || typeof url !== 'string') {
    return null;
  }

  if (url.includes(WWW_CHECK_STRING)) {
    return url;
  }

  return url.replace(URL_WITHOUT_WWW, URL_WITH_WWW);
};

export const appendUrlParams = (baseUrl, params) => {
  const separator = baseUrl.includes('?') ? '&' : '?';

  return `${baseUrl}${separator}${params}`;
};
