import gql from 'graphql-tag';

export const SEARCHES = gql`
  query searches {
    searches {
      searchUrl
      vertical
      searchObject {
        title
        city
        osmId
        locationName
        jobTypes
        jobProviders
        salaryMin
        salaryMax
      }
      createdAt
      id
    }
  }
`;

export const DELETE_SEARCH_AGENT_MUTATION = gql`
  mutation deleteSearch($id: Int!) {
    deleteSearch(id: $id)
  }
`;
