import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Auth from '@aws-amplify/auth';

import { generateSecurityHash } from '../libs/helpers/securityHelpers';

export const hashMiddleware = new ApolloLink((operation, forward) => {
  const { variables } = operation || {};
  const securityHash = generateSecurityHash(variables);

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-authorization': `Bearer ${securityHash}`,
    },
  }));

  return forward(operation);
});

export const tokenMiddleware = setContext((_, { headers }) => {
  const USER_TOKEN_STRING = 'userTokens';
  const userTokens = localStorage.getItem(USER_TOKEN_STRING);
  const { idToken } = JSON.parse(userTokens) || {};

  if (idToken) {
    localStorage.removeItem(USER_TOKEN_STRING);

    return {
      headers: {
        ...headers,
        jwttoken: idToken,
      },
    };
  }

  return Auth.currentAuthenticatedUser()
    .then((data) => {
      const { signInUserSession } = data || {};
      const { idToken } = signInUserSession || {};
      const { jwtToken } = idToken;

      return {
        headers: {
          ...headers,
          jwtToken,
        },
      };
    })
    .catch((error) => ({
      headers: {
        ...headers,
      },
    }));
});
