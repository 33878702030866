import gql from 'graphql-tag';

export const RE_UNSUBSCRIBE_SAVE_SEARCH = gql`
  mutation ($id: Int!, $secureHash: String!) {
    unsubscribeSaveSearch: unsubscribeSaveSearch(id: $id, secureHash: $secureHash) {
      searchUrl
    }
  }
`;

export const JOBS_UNSUBSCRIBE_SAVE_SEARCH = gql`
  mutation ($id: Int!, $secureHash: String!) {
    unsubscribeSaveSearch: unsubscribeSaveSearch(id: $id, secureHash: $secureHash) {
      searchUrl
    }
  }
`;
