import React from 'react';
import { useTranslation } from 'react-i18next';

import { Links, LinkDst } from 'shared-frontend-components/dist/index';

import { realEstateUrl, jobsUrl } from '../../config/appConfig';

export const HeaderPageSwitch = () => {
  const { t } = useTranslation('common');

  return (
    <Links
      linksData={[
        {
          text: t('pages.realEstate'),
          url: realEstateUrl,
          activeClass: '',
          project: 'base',
        },
        {
          text: t('pages.jobs'),
          url: jobsUrl,
          activeClass: '',
          project: 'base',
        },
      ]}
      linkRender={(link) => (
        <LinkDst
          url={link.url}
          text={link.text}
          activeClassName={link.activeClass}
          project={link.project}
        />
      )}
    />
  );
};
