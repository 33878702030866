import React from 'react';
import { withRouter } from 'react-router-dom';

import { Header, SharedFooter, LayoutWrapper } from '.';

export const Layout = withRouter(({ children, className, history }) => {
  const { location } = history || {};
  const { pathname } = location || {};

  let headerModifiers = {};
  let footerModifiers = {};

  if (pathname.indexOf('/authorization') === 0) {
    headerModifiers = { ...headerModifiers, neutral: true };
    footerModifiers = { ...footerModifiers, hidden: true };
  }

  return (
    <div className={className}>
      <LayoutWrapper>
        <Header {...headerModifiers} />
        {children}
        <SharedFooter {...footerModifiers} />
      </LayoutWrapper>
    </div>
  );
});
