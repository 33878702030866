import React from 'react';

import { Logo, HeaderDst } from 'shared-frontend-components/dist/index';

import { ReactComponent as LogoRealEstate } from '../../assets/img/finden-logo.svg';

import { HeaderRight } from './HeaderRight';
import { HeaderPageSwitch } from './HeaderPageSwitch';

export const Header = () => {
  const logo = <Logo logoClassName="header-base-logo" whereTo="/" logo={<LogoRealEstate />} />;

  return (
    <HeaderDst
      logo={logo}
      classNameContainer="header-base-container"
      renderLeftMiddleSection={null}
      renderRightMiddleSection={<HeaderPageSwitch />}
      renderRightSection={<HeaderRight />}
    />
  );
};
