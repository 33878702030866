import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { PageLoader } from 'shared-frontend-components/dist/index';

import { UserContext } from '../../libs/context';
import { AuthorizationRenderer } from '../authorization/AuthorizationRenderer';
import { NotFound } from '../../pages';

export const AuthorizedRoute = (props) => {
  const { isLoggedUser, isLoading } = useContext(UserContext);

  const renderAuthorized = () => <Route {...props} />;
  const renderNonAuthorized = () => <Redirect to="/authorization" />;
  const renderLoading = () => <PageLoader />;

  return (
    <AuthorizationRenderer
      loading={isLoading}
      authorized={isLoggedUser}
      renderAuthorized={renderAuthorized}
      renderNonAuthorized={renderNonAuthorized}
      renderLoading={renderLoading}
    />
  );
};

export const NonAuthorizedRoute = (props) => {
  const { isLoggedUser, isLoading } = useContext(UserContext);

  const renderAuthorized = () => <NotFound />;
  const renderNonAuthorized = () => <Route {...props} />;

  return (
    <AuthorizationRenderer
      loading={isLoading}
      authorized={isLoggedUser}
      renderAuthorized={renderAuthorized}
      renderNonAuthorized={renderNonAuthorized}
    />
  );
};
