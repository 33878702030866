import TagManager from 'react-gtm-module';
import slug from 'slug';
import { parse, stringify } from 'query-string';
import lozad from 'lozad';

import { ENVIRONMENT, publicUrl } from '../../config/appConfig';

const appVertical = 'base';

export const pageLoadEvent = (pageLoadData) =>
  pushDataLayer({
    event: 'Page Load',
    siteId: 'finden.at',
    vertical: appVertical,
    pageType: 'home',
    environment: ENVIRONMENT,
    userLoggedIn: false,
    ...pageLoadData,
  });

export const btnClickEvent = (btnClickData) => pushDataLayer(btnClickData);

export const pushDataLayer = (dataLayer) => {
  const { category, event, ...restDataLayer } = dataLayer;

  const modifiedDataLayer = { event: `${category}.${event}`, ...restDataLayer };

  const tagManagerArgs = {
    dataLayer: category ? modifiedDataLayer : dataLayer,
    vertical: appVertical,
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const slugifyString = (inputString = '') => {
  if (typeof inputString !== 'string') return '';

  return slug(inputString, { lower: true });
};

export const slugifyUrlString = (inputString = '') => {
  if (typeof inputString !== 'string') return '';
  try {
    const { pathname } = new URL(inputString);
    const decodedPathname = decodeURIComponent(pathname);
    const newPathname = decodedPathname
      .split('/')
      .map((item) => slugifyString(item))
      .join('/');

    return inputString.replace(decodedPathname, newPathname);
  } catch (e) {
    return inputString
      .split('/')
      .map((item) => slugifyString(item))
      .join('/');
  }
};

export const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(String(email).toLowerCase());
};

export const getCurrentQuery = (query) => parse(query);

export const setQueryString = (query, route, history, strategy, withHash) => {
  const historyStrategy = strategy === 'replace' ? history.replace : history.push;
  const stringifiedQuery = stringify(query);

  if (withHash && window.location.hash) {
    historyStrategy(`${publicUrl}/${route}/?${stringifiedQuery}${window.location.hash}`);
  } else {
    historyStrategy(`${publicUrl}/${route}/?${stringifiedQuery}`);
  }
};

export const buildUrlForBookmarkItem = ({ vertical, itemId }) => {
  const { hostname } = window.location;
  let host = hostname;

  if (window.location.hostname === 'localhost') {
    host = `http://${host}:3000`;
  } else {
    host = `https://${host}`;
  }

  return `${host}/${vertical}/detail/${itemId}`;
};

export const buildUrlForSearchAgentItem = (searchUrl) => {
  const { hostname } = window.location;

  return hostname === 'localhost'
    ? `http://${hostname}:3000${searchUrl}`
    : `https://${hostname}${searchUrl}`;
};

export const enrichObjectByVertical = ({ vertical, object }) => ({
  ...object,
  vertical: vertical,
});

export const enrichObjectByDateAdded = ({ dateAdded, object }) => ({
  ...object,
  dateAdded: dateAdded,
});

const observer = lozad();

let timer = false;

const launchObserver = () => {
  observer.observe();
  timer = false;
};

export const checkLazyLoad = () => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(launchObserver, 5);
};

export const formateDate = (rawDate) => {
  const date = new Date(rawDate);

  return date.toLocaleDateString('de-DE');
};

export const sortBookmarksByDate = (itemsList = []) => {
  const dateComp = (objectA, objectB) => {
    const dateA = objectA.createdAt || (objectA.favoriteObject && objectA.favoriteObject.createdAt);
    const dateB = objectB.createdAt || (objectB.favoriteObject && objectB.favoriteObject.createdAt);

    return dateB - dateA;
  };

  return itemsList.sort(dateComp);
};

export const getNewMessagesCount = (messageArray = []) =>
  messageArray.filter((message) => message.status === 'unseen').length;

export const getFormatDate = (dateString) => Intl.DateTimeFormat('de-DE').format(dateString);

export const isEnvironmentProduction = ENVIRONMENT === 'production';

export const safeJoinArrays = (...arrays) => {
  return arrays.flatMap((array) => array || []);
};
