import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Authorization, AuthorizationEmailSent } from 'shared-frontend-components/dist/index';

import { AuthorizationEmailExpired } from '../../modules';
import { UserContext, AppContext } from '../../libs/context';
import { validateEmail, btnClickEvent, getCurrentQuery } from '../../libs/helpers/helpers';
import { signInProcess } from '../../libs/authorization';

export const AuthorizationPage = () => {
  const {
    cognitoUser,
    setCognitoUser,
    extendedPageLoadEvent: pageLoadEvent,
  } = useContext(UserContext);
  const { pageReferrer, setPageReferrer } = useContext(AppContext);
  const { t } = useTranslation('common');
  const query = getCurrentQuery(window.location.search);
  const expiredLink = query.expired === 'true';

  useEffect(() => {
    const { referrer } = document;

    if (referrer && !pageReferrer) {
      setPageReferrer(referrer);
    }

    pageLoadEvent({
      pageType: 'authorization',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Route
        exact
        path="/authorization"
        render={(props) => (
          <Authorization
            {...props}
            pageReferrer={pageReferrer}
            cognitoUser={cognitoUser}
            setCognitoUser={setCognitoUser}
            validateEmail={validateEmail}
            btnClickEvent={btnClickEvent}
            getCurrentQuery={getCurrentQuery}
            signInProcess={signInProcess}
            error={t('authorization.validationError')}
            title={t('authorization.singIn')}
            subtitle={t('authorization.signInInstructions')}
            layoutClassName="finden-authorization"
            inputLabel={t('authorization.emailAddress')}
            inputErrorMessage={t('authorization.emailError')}
            buttonLabel={t('authorization.loginRequest')}
            isLinkExpired={expiredLink}
            linkExpiredWarningText={t('authorization.expiredLink')}
          />
        )}
      />
      <Route
        path="/authorization/email-sent"
        render={(props) => (
          <AuthorizationEmailSent
            {...props}
            cognitoUser={cognitoUser}
            setCognitoUser={setCognitoUser}
            titleEmailSent={t('authorization.checkInbox')}
            emailSwitchTitle={t('authorization.emailSwitchTitle')}
            type="auth"
            checkInboxTextFirstParagraph={t('authorization.checkInboxTextFirstParagraph')}
            checkInboxTextSecondParagraph={t('authorization.checkInboxTextSecondParagraph')}
            checkInboxTextThirdParagraph={t('authorization.checkInboxTextThirdParagraph')}
            back={t('authorization.back')}
            backLink="/"
          />
        )}
      />
      <Route
        path="/authorization/expired"
        render={(props) => (
          <AuthorizationEmailExpired
            {...props}
            cognitoUser={cognitoUser}
            setCognitoUser={setCognitoUser}
          />
        )}
      />
    </>
  );
};
