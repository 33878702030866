import React from 'react';

export const ListCommonWarning = ({ show, noItems }) => {
  if (!show) {
    return null;
  }

  return (
    <div>
      <span className="bookmarks-no-items-message">{noItems}</span>
    </div>
  );
};
