import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { AuthorizationLayout } from 'shared-frontend-components/dist/index';
import { Button, BUTTON_VARIANT, BUTTON_SIZE } from 'shared-frontend-components/dist/atoms';

import './_authorizationEmailExpired.scss';

export const AuthorizationEmailExpired = withRouter(({ history }) => {
  const { t } = useTranslation('common');

  const handleButtonClick = () => {
    const { push } = history || {};

    if (push) {
      push('/authorization');
    }
  };

  return (
    <AuthorizationLayout
      title={t('authorization.emailExpired')}
      subtitle={t('authorization.emailExpiredText')}
      className="finden-authorization-email-expired"
    >
      <Button
        variant={BUTTON_VARIANT.PRIMARY}
        size={BUTTON_SIZE.DEFAULT}
        isFullWidth
        onClick={handleButtonClick}
      >
        {t('authorization.emailExpiredButton')}
      </Button>
    </AuthorizationLayout>
  );
});
