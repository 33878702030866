import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ListCommonHeader, ListCommonSkeletonLoader, ListCommonWarning } from '../../components';
import { useJobsQuery } from '../../config/api';
import { UserContext } from '../../libs/context/UserContext';
import { sortBookmarksByDate } from '../../libs/helpers/helpers';
import { BookmarkItem } from './bookmarkItem/BookmarkItem';
import { GET_FAVOR_JOBS_QUERY } from './bookmarkQueries';

import './_bookmarksList.scss';

export const BookmarksList = () => {
  const { t } = useTranslation('common');
  const { isLoggedUser, extendedPageLoadEvent: pageLoadEvent } = useContext(UserContext);

  const {
    data: jobsData,
    loading: isLoading,
    errors: jobsErrors,
  } = useJobsQuery(GET_FAVOR_JOBS_QUERY, {
    skip: !isLoggedUser,
  });

  useEffect(() => {
    pageLoadEvent({
      pageType: 'service',
      serviceFeature: 'bookmarks',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (jobsErrors) {
    console.error({ jobsErrors });
  }

  const { getFavoredJobs: favorJobs = [] } = jobsData || {};

  const showNoItemsWarning = !favorJobs.length && !isLoading;
  const totalCount = favorJobs.length;
  const items = sortBookmarksByDate([...favorJobs]);

  return (
    <div className="bookmarks-list">
      <ListCommonHeader
        title={t('profile.bookmarks.title')}
        numberTitle={t('profile.ads', { count: totalCount })}
        totalCount={totalCount}
      />
      {isLoading ? (
        <ListCommonSkeletonLoader image />
      ) : (
        <ul className="bookmarks-list-unordered-list">
          {items.map((item, idx) => (
            <BookmarkItem bookmarkItem={item.job} key={idx} />
          ))}
        </ul>
      )}
      {showNoItemsWarning && <ListCommonWarning show noItems={t('profile.noBookmarkItems')} />}
    </div>
  );
};
