export const AuthorizationRenderer = ({
  loading,
  authorized,
  renderAuthorized,
  renderNonAuthorized,
  renderLoading,
}) => {
  if (loading && renderLoading) {
    return renderLoading();
  } else if (authorized && renderAuthorized) {
    return renderAuthorized();
  } else if (!authorized && renderNonAuthorized) {
    return renderNonAuthorized();
  }

  return null;
};
