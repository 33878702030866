import gql from 'graphql-tag';

export const GET_FAVOR_JOBS_QUERY = gql`
  query getFavoredJobs {
    getFavoredJobs {
      job {
        id
        title
        city
        referencenumber
        company
        logoUrl
      }
      createdAt
    }
  }
`;

export const DELETE_FAVOR_JOB_MUTATION = gql`
  mutation deleteFavorJob($jobReferenceNumber: String!) {
    deleteFavorJob(jobReferenceNumber: $jobReferenceNumber)
  }
`;
