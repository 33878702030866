import Auth from '@aws-amplify/auth';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import hyperid from 'hyperid';

import {
  COGNITO_POOL_ID,
  COGNITO_POOL_WEB_CLIENT_ID,
  AWS_REGION,
  AUTH_API_URL,
  AUTH_API_KEY,
} from '../config/appConfig';

const USER_EXISTS_EXCEPTION = 'UsernameExistsException';

Auth.configure({
  region: AWS_REGION,
  userPoolId: COGNITO_POOL_ID,
  userPoolWebClientId: COGNITO_POOL_WEB_CLIENT_ID,
});

const defaultEmailObject = {
  type: 'default',
};

const sentToEmailControlAPI = (email, value) => {
  const rawData = JSON.stringify({ userEmail: email, emailTemplateObject: value });
  const requestOptions = {
    method: 'POST',
    body: rawData,
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': AUTH_API_KEY,
    },
  };
  const endpoint = `${AUTH_API_URL}/authorizationStoreAdditionalData`;

  return fetch(endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result.success;
    })
    .catch((error) => {
      console.log('Auth API error', error);

      return false;
    });
};

export const sendVerifyCodeToControlAPI = (email = '', code) => {
  const rawData = JSON.stringify({ userEmail: email, secretCode: code });
  const requestOptions = {
    method: 'POST',
    body: rawData,
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': AUTH_API_KEY,
    },
  };
  const endpoint = `${AUTH_API_URL}/authorizationVerifyCode`;

  return fetch(endpoint, requestOptions)
    .then((response) => response.json())
    .catch((error) => {
      console.log('Auth API error', error);

      return false;
    });
};

export const signInProcess = async (email, emailTemplateObject = defaultEmailObject) => {
  if (defaultEmailObject.type !== 'default') {
    // Values for emailTemplateName can be found in lambda-authorization repo,
    // path src/create-auth-challenge/application/email-template/templates/getTemplete.js
    const APIResponse = await sentToEmailControlAPI(email, emailTemplateObject);

    if (!APIResponse) {
      return false;
    }
  }

  try {
    return await signIn(email);
  } catch (error) {
    console.log('login ended with error ', error);
  }

  try {
    await signUp(email);
  } catch (error) {
    if (error.code !== USER_EXISTS_EXCEPTION) {
      return false;
    }
  }

  try {
    return await signIn(email);
  } catch (error) {
    console.log('login ended with error ', error);
  }
};

const signIn = async (email) => {
  const user = await Auth.signIn(email);

  localStorage.setItem(
    'cognitoUser',
    JSON.stringify({
      username: email,
      session: user.Session,
    }),
  );

  return user;
};

export const answerCustomChallenge = async (answer) => {
  const user = JSON.parse(localStorage.getItem('cognitoUser'));

  if (!user) {
    return false;
  }

  const poolData = {
    UserPoolId: COGNITO_POOL_ID,
    ClientId: COGNITO_POOL_WEB_CLIENT_ID,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const userData = {
    Username: user.username,
    Pool: userPool,
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  cognitoUser.Session = user.session;

  cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

  try {
    const response = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);

    if (response.signInUserSession) {
      return true;
    }

    return false;
  } catch (error) {
    console.log('Error while anwering custom challange: ', error);

    return false;
  }
};

const signUp = async (email) => {
  const instance = hyperid();
  const id = instance();
  const generatedUuid = hyperid.decode(id, { urlSafe: true }).uuid;

  const params = {
    username: generatedUuid,
    password: getRandomString(30),
    attributes: {
      email: email,
    },
  };

  try {
    await Auth.signUp(params);
  } catch (error) {
    console.error('Error signing up', error);
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
    localStorage.removeItem('cognitoUser');
  } catch (error) {
    console.log('where was a problem while logging out', error);
  }
};

const getRandomString = (bytes) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);

  return Array.from(randomValues).map(intToHex).join('');
};

const intToHex = (nr) => {
  return nr.toString(16).padStart(2, '0');
};
