import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';

import { App } from './App';
import { i18n } from './config/i18n';
import { TAG_MANAGER_ID } from './config/appConfig';
import { appendCookieConsentScript } from './libs/cookieScript';

import 'intersection-observer';
import 'shared-frontend-components/dist/index.css';
import './assets/styles.scss';

appendCookieConsentScript();

TagManager.initialize({
  gtmId: TAG_MANAGER_ID,
});

ReactDOM.render(
  <Router basename="/">
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Router>,
  document.getElementById('root'),
);
